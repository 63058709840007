//import 'ol/ol.css';
//import 'ol-geocoder/dist/ol-geocoder.css';
import 'material-design-icons/iconfont/material-icons.css';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import Stamen from 'ol/source/Stamen';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import proj4 from 'proj4';
import {register} from 'ol/proj/proj4';
import {get as getProjection} from 'ol/proj';
import {getTopLeft, getWidth} from 'ol/extent';
import Geocoder from 'ol-geocoder';

// Register ESPG:27700 projection (British National Grid).
proj4.defs('EPSG:27700', '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs');
register(proj4);
const projection = getProjection('EPSG:27700');
projection.setExtent([0, 0, 700000, 1300000]);

var geocoder = new Geocoder('nominatim', {
  provider: 'osm',
  lang: 'en',
  countrycodes: 'gb',
  placeholder: 'Search for...',
  limit: 5,
  debug: false,
  autoComplete: true,
  autoCompleteTimeout: 500,
  keepOpen: true,
  preventDefault: true,
});

var starting = [
  [499751.4129004567, 188353.39013887517],
  [500885.87370953965, 349469.5909630966],
  [227316.38561594544, 374778.50914075144],
  [270060.51329470694, 87555.74342867202],
  [525653.8854573226, 183787.97332433728],
  [534139.3344808898, 179505.76537187007],
  [588316.1484153239, 149679.23705560862],
  [414239.69813530124, 114774.62927605919],
  [545321.9389410083, 258308.52426946745],
  [451480.5753875889, 206285.0677709098],
  [482822.06010147394, 198727.78787199152],
  [345515.7986342927, 721364.4555354728],
];



var map = new Map({
  layers: [
    new TileLayer({
      source: new Stamen({
        layer: 'watercolor',
      }),
      minResolution: 5,
    }),
    new TileLayer({
      maxResolution: 5,
      source: new WMTS({
        requestEncoding: 'REST',
        url: 'tiles/{TileRow}/{TileCol}.png',
        format: 'image/png',
        projection: projection,
        tileGrid: new WMTSTileGrid({
          extent: projection.getExtent(),
          resolutions: [2.5],
          matrixIds: ['25K'],
          tileSize: 200
        }),
        style: 'default',
      }),
    })],
  target: 'map',
  view: new View({
    center: starting[Math.floor(Math.random() * starting.length)],
    projection: projection,
    extent: projection.getExtent(),
    smoothExtentConstraint: false,
    resolutions: [850, 650, 400, 200, 100, 50, 25, 10, 4, 2.5],
    constrainResolution: true,
    resolution: 4,
  }),
});
map.addControl(geocoder);
geocoder.on('addresschosen', function(event) {
  console.log(event);
  map.getView().setCenter(event.coordinate);
  if (map.getView().getResolution() >= 4) {
    map.getView().setResolution(4);
  }
});
window.map = map;